<template>
<div class="welcome">
  <div class="main">
    <h2>欢迎您登录</h2>
    <h2>托育机构质量评估标准平台</h2>
    <el-button @click="create" v-if="!num_all" class="creat-report" type="primary">创建评估</el-button>
    <el-button @click="jump" v-if="num_all" class="creat-report" type="primary">继续评估</el-button>
  </div>
</div>
</template>

<script>
export default {
  name: "welcome",
  data() {
    return {
      num_all: 0
    };
  },
  methods: {
    jump() {
      this.$router.push('/table/school_not_submit')
    },
    async getData() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/index",
        data: {
          page: 1,
          limit: 10,
          type: 1,
          inspect_type: 1
        }
      });
      if (res.code == 0) {
        this.num_all = res.data.num_all || 0;
      } else {
       
      }
    },
    async create() {
      this.createLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/create",
      });
      if (res.code == 0) {
        this.$message.success('创建成功');
        this.$router.push({
          path: "/inspect",
          query: {
            inspect_id: res.data.inspect_id,
            backPage: '/table/school_not_submit'
          }
        });
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
      this.createLoading = false;
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100vw;
  height: calc(100vh - 128px);
  background: url("../assets/index/index_bg.png") no-repeat;
  background-size: 100%;

  .main {
    margin-right: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 50px;

    h2 {
      margin: 5px auto;
      text-align: left;
      width: 100%;
    }

    .creat-report {
      width: 193px;
      margin-top: 40px;
    }
  }
}
</style>
